import React, { Component } from 'react'


export default class ShopCompleted extends Component {
    render() {
        return (
            <div className="shopping-cart-form">
                Checkout completed! Thank you for your purchase. You will be contacted within 24 hours.
            {/* <a src="https://os-personal-project.s3-us-west-1.amazonaws.com/ochiba2013_blk.PNG" /> */}
            </div>
        )
    }
}
