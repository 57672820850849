import React from 'react'
import CarouselHome from './Carousel'

import './Home.css'

const Home = () => 
<div className="home-carousel">
<CarouselHome />
</div>


export default Home;